<template>
	<b-modal id="cancel-maintenance" :title="title" ref="modal" ok-title="Proceed" ok-variant="danger" 
		cancel-title="Close" @ok="handleOk" :cancel-disabled="disableConfirmButtons" 
		:ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to cancel
			<strong>{{ selMaintenance.maintenanceId }}</strong>
			?
		</div>
	</b-modal>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';

// API
import maintenanceApi from '@/api/maintenanceApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'cancel-maintenance',
	components: {
		Loading,
	},
	data() {
		return {
			selMaintenance: {},
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Cancel Maintenance';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateMaintenance', (maintenance) => {
			if (!_.isEmpty(maintenance)) {
				this.selMaintenance = maintenance;
			}
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selMaintenance.dateCancelled = DateUtil.getCurrentTimestamp();
			this.selMaintenance.cancelledBy = this.loggedUser.id;

			let maintenanceId = this.selMaintenance.maintenanceId;
			try {
				let { data } = await maintenanceApi.cancelMaintenance(
					this.selMaintenance,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.$toaster.success(`Maintenance "${maintenanceId}" was cancelled successfully.`);
					EventBus.$emit('onCloseSaveMaintenance', data.maintenance);
					this.$refs.modal.hide();

				} else {
					this.$toaster.warning(data.message);
				}

			} catch (error) {
				this.$toaster.error(`Error cancelling maintenance "${maintenanceId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateMaintenance');
	},
};
</script>