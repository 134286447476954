<template>
	<b-card>
		<b-tabs>
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-row class="my-12">
						<b-col class="mt-4" lg="6" md="6" sm="12">
							<b-form-group label="Company" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getArea }}
							</b-form-group>

							<b-form-group label="Asset Type" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.assetType }}
							</b-form-group>

							<b-form-group label="Status" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.status }}
							</b-form-group>

							<b-form-group label="Date Created" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getFormattedDate(row.item.dateCreated) }}
							</b-form-group>

							<b-form-group label="Created By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.createdBy) }}
							</b-form-group>

							<b-form-group label="Date Updated" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getFormattedDate(row.item.dateUpdated) }}
							</b-form-group>
							<b-form-group label="Updated By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.updatedBy) }}
							</b-form-group>

							<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled"
								label-cols-sm="4" label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getFormattedDate(row.item.dateCancelled) }}
							</b-form-group>
							<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By"
								label-cols-sm="4" label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.cancelledBy) }}
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<canvas id="classification-chart"></canvas>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Supporting Documents">
				<b-card-text>
					<b-row>
						<b-col v-for="(document, id) in row.item.documents" :key="id" lg="4" md="4" sm="6">
							<b-row class="mx-0"><b>{{ document.name }}</b></b-row>
							<b-row class="mx-0 mt-2">
								<b-img v-img-orientation-changer :src="document.url" alt="Responsive image" thumbnail />
							</b-row>
							<b-row class="mx-0 mt-2">
								<a href="#" class="pull-left" @click.prevent="showImage(document.url)">
									Click to view full image
								</a>
							</b-row>
							<b-row class="mx-0 mt-2">
								<b-form-group label="Remarks" label-cols-sm="12" label-class="font-weight-bold pt-0">
									<span class="truncate-text">
										<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
											less="Show Less" type="html" :text="document.remarks" />
									</span>
								</b-form-group>
							</b-row>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Repaired Batches" v-if="items !== null && items.length !== 0">
				<b-card-text>

					<b-row class="mb-2 mt-2">
						<b-col sm="5">
							<b-form-group label="Total" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="repair-count">{{ totalQuantity }}</span>
							</b-form-group>
						</b-col>
						<b-col sm="7">

							<b-form-group label="Repaired" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalQuantity" height="1rem">
									<b-progress-bar :value="totalRepaired">
										<span>
											<strong>{{ totalRepaired }} / {{ totalQuantity }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>

							<b-form-group label="Checked" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalQuantity" height="1rem">
									<b-progress-bar :value="totalChecked">
										<span>
											<strong>{{ totalChecked }} / {{ totalQuantity }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>

							<b-form-group label="Endosed" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalQuantity" height="1rem">
									<b-progress-bar :value="totalEndorsed">
										<span>
											<strong>{{ totalEndorsed }} / {{ totalQuantity }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mb-2 ml-3 mr-3">
						<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive>

							<template v-slot:cell(repaired)="row">
								<div v-if="isNonNullStr(row.item.repaired.repairedBy)">
									<span v-for="(item, index) in row.item.classifications" :key="index">
										{{ `${item.condition} : ${item.quantity}` }} <br />
									</span>
								</div>
							</template>
							<template v-slot:cell(checked)="row">
								<div v-if="isNonNullStr(row.item.checked.checkedBy)">
									<span v-for="(item, index) in row.item.classifications" :key="index">
										{{ `${item.condition} : ${item.quantity}` }} <br />
									</span>
								</div>
							</template>
							<template v-slot:cell(endorsed)="row">
								<div v-if="isNonNullStr(row.item.endorsed.endorsedBy)">
									<span v-for="(item, index) in row.item.classifications" :key="index">
										{{ `${item.condition} : ${item.quantity}` }} <br />
									</span>
								</div>
							</template>
							<template v-slot:cell(status)="row">
								<span>{{ getBatchStatus(row.item) }}</span>
							</template>
							<template v-slot:cell(actions)="row">
								<RepairedBatchRowActions :row="row" :selRepairCondition="selRepairCondition"
									:selAssetType="selAssetType" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer" />
							</template>
						</b-table>
					</b-row>
					<b-row class="mx-0">
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
								v-model="currentPage" class="my-0" size="sm" />
						</b-col>
					</b-row>

				</b-card-text>
			</b-tab>
		</b-tabs>

		<InvalidImageDialog />
	</b-card>
</template>

<script>
// Component
import InvalidImageDialog from '../common/InvalidImageDialog.vue';

// DAO
import maintenanceDAO from '@/database/maintenances'

// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';
import { UserUtil } from '@/utils/userutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Chart from 'chart.js';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'maintenance-details-view',
	components: {
		InvalidImageDialog,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				'batchNo',
				{
					key: 'repaired',
					label: 'Repaired',
					sortable: true,
				},
				{
					key: 'checked',
					label: 'Checked',
					sortable: true,
				},
				{
					key: 'endorsed',
					label: 'Endorsed',
					sortable: true,
				},
				'status',
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalQuantity: 0,
			totalRepaired: 0,
			totalChecked: 0,
			totalEndorsed: 0,
		}
	},
	computed: {
		getArea() {
			return MaintenanceUtil.getAreaDisplay(this.row.item);
		},
		total() {
			let item = this.row.item;
			return item.disposedCount + item.repairCount;
		}
	},

	async mounted() {

		const colorsArr = [
			`rgb(18, 43, 145)`,
			`rgb(228, 143, 1)`,
			`rgb(12, 124, 89)`,
			`rgb(228, 116, 119)`,
			`rgb(166, 61, 64)`,
		];

		const labelsArr = [];
		const quantitiesArr = [];
		for (const item of this.row.item.conditions) {
			labelsArr.push(item.condition);
			quantitiesArr.push(item.quantity);
		}

		const ctxDOM = document.getElementById('classification-chart');
		new Chart(ctxDOM, {
			type: 'pie',
			data: {
				labels: labelsArr,
				datasets: [
					{
						data: quantitiesArr,
						borderWidth: 1,
						backgroundColor: colorsArr,
						fontSize: 20,
					},
				],
			},
			options: {
				legend: {
					position: 'right',
					labels: {
						padding: 10,
						fontStyle: 'bold',
					},
				},
				responsive: true, // Enable responsive sizing
				maintainAspectRatio: false, // Disable aspect ratio constraint
			},
		});

		this.onInitBatches();
	},

	methods: {
		async onInitBatches() {
			let batchesObj = await maintenanceDAO.getBatches(this.row.item.id);
			this.items = Object.values(batchesObj);

			for (const item of this.items) {

				const classifications = item.classifications;
				for (const classification of classifications) {

					this.totalQuantity += classification.quantity;
					if (item.repaired && this.isNonNullStr(item.repaired.repairedBy)) {
						this.totalRepaired += (classification.quantity - classification.rejected);
					}

					if (item.checked && this.isNonNullStr(item.checked.checkedBy)) {
						this.totalChecked = (classification.quantity - classification.rejected);
					}

					if (item.endorsed && this.isNonNullStr(item.endorsed.endorsedBy)) {
						this.totalEndorsed = (classification.quantity - classification.rejected);
					}
				}
			}
		},

		isNonNullStr(str) {
			return str && str.length !== 0;
		},

		getUserDisplay(userId) {
			return UserUtil.getUserDisplay(this.allUsersObj, userId);
		},

		getBatchStatus(batch) {
			if (batch.completed) {
				return 'Done';
			} else if (batch.status === config.batchStatus.IN_PRODUCTION.name) {
				return config.batchStatus.IN_PRODUCTION.nextStatus;
			} else if (batch.status === config.batchStatus.IN_QC.name) {
				return config.batchStatus.IN_QC.nextStatus;
			} else if (batch.status === config.batchStatus.IN_WAREHOUSE.name) {
				return config.batchStatus.IN_WAREHOUSE.nextStatus;
			}

			return '-';
		},

		getFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},

		getPercentageOf(value, total) {
			return (value / total) * 100;
		},

		showImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		hasMedia(media) {
			return media && media.url && !_.isEmpty(media.url);
		},

		hasUrl(url) {
			return url && !_.isEmpty(url);
		},
	},
};
</script>

<style scoped>
.repair-count {
	font-size: 5em;
	/* font-weight: bold; */
}
</style>
